import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationProjectService } from 'src/app/core/application-project/services/application-project.service';
import { ApplicationSettingsComponent } from 'src/app/core/application-settings/application-settings.component';
import { ExcludedDemandService } from 'src/app/core/excluded-demand/excluded-demand.service';
import { OrderFormService } from 'src/app/core/orders-forms/services/order-form.service';
import { EcheancesService } from 'src/app/core/pack-generation/calculate-echeances/services/echeances.service';
import { UsersService } from 'src/app/core/user/services/users.service';
import { AlertLevel, AlertVue, IAlert } from 'src/app/shared/models/alert';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root',
})
export class JiraImportService {
  private API_URL: string = environment.backendApiUrl + '/import';
  private STATE_HEADER = 'Etat';
  private SERVICE_TYPE_HEADER =
    'Champs personnalisés (Type de service - CDS Prod train)';
  private DEFAULT_CORRECT = 'CORRECTION-DEFAUT';
  private ESTIMATED_QUOTE = 'DEVIS-ESTIMATIF';

  private DEMAND_TYPE_HEADER = 'Type de ticket';
  private DEMAND = 'Demande';

  private headers: Array<string>;

  public jiraErrors?: any;

  alert!: IAlert;

  severity!: AlertLevel;
  comment!: string;
  type!: AlertVue;

  debugModeActivated = false;


  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private _al: AlertService,
    private _ap: ApplicationProjectService,
    private _us: UsersService,
    private _of: OrderFormService,
    private _exd: ExcludedDemandService,
    private _es: EcheancesService,
    public authenticationService: AuthenticationService,
    @Inject(Papa) private papa: Papa
  ) {
    this.headers = []; //Used in transformHeader: gets filled with existing headers
    this.debugModeActivated = this.authenticationService.getDebugMode();
  }

  eraseErrors() {
    this.jiraErrors = [];
  }

  ping(): Observable<JSON> {
    return this.http
      .get<JSON>(environment.backendApiUrl + '/pack/ping')
      .pipe(catchError(this.handleError));
  }

  postFileToBack(file: File, asc: ApplicationSettingsComponent) {
    const startDate = new Date();
    this.headers = []
    let generatedJSON: any;

    this._es.getAltFields().subscribe({
      next: (data) => {
        const altFields: string[][] = data;
        this.papa.parse(file, {
          download: true,
          header: true,
          dynamicTyping: true,
          encoding: 'utf-8',
          delimiter: '', // auto-detect
          delimitersToGuess: [',', ';'],
          newline: '\r\n',
          beforeFirstChunk: function (chunk) {
            // Remove quotes at the beginning and end of the line, and the semicolon at the end of the line if present
            const rows = chunk.split(/\r\n|\r|\n/);
            const modifiedRows = rows.map((row) => {
              // Remove the quote at the beginning of the line if present
              if (row.startsWith('"')) {
                row = row.substring(1);
              }
              // Remove the semicolon at the end of the line if present
              if (row.endsWith(';')) {
                row = row.substring(0, row.length - 1);
              }
              // Replace coma with dot in numbers and delete space before number
              row = row.replace(/"\s(-?\d+\,\d+)"/g, '$1');

              // Replace dot by coma in number for number without double quotes
              row = row.replace(/(;-?\d+\.\d+;)/g, function (match) {
                return match.replace('.', ',');
              });

              // Replace &amp; with &
              row = row.replace(/&amp;/g, '&');

              // remove € symbol
              row = row.replace(/€/g, '');

              // remove space into number
              row = row.replace(/"(\d+)\s(\d+)"/g, '$1$2');
              row = row.replace(/"(\d+)\s(\d+),(\d+)"/g, '$1$2,$3');
              row = row.replace(/"(-\d+)\s(\d+),(\d+)"/g, '$1$2,$3');
              row = row.replace(/"(\d+)\s(\d+),(\d+)\s"/g, '$1$2,$3'); // variant avec un espace apres le nombre
              row = row.replace(/"(-\d+)\s(\d+),(\d+)\s"/g, '$1$2,$3'); // variant avec un espace apres le nombre

              // Remove space after number
              row = row.replace(/"(\d+)\s"/g, '$1');
              row = row.replace(/"(\d+),(\d+)\s"/g, '$1,$2');

              //Remove space before number
              row = row.replace(/\s"(\d+)/g, '$1');
              row = row.replace(/\s"(\d+),(\d+)/g, '$1,$2');

              // remove space into and after number
              row = row.replace(/(\d{1,3})(\s)(\d{3},\d{2})/g, '$1$3');

              // replace coma with dot in negative numbers with double double quotes and remove space
              row = row.replace(/""(-\d+),(\d+)\s""/g, '$1,$2');

              // Replace double quotes with single quotes around numbers and remove space
              row = row.replace(/""(\d+),(\d+)\s""/g, '"$1"');

              // replace coma with dot in negative numbers with double quotes
              row = row.replace(/"(-\d+),(\d+)"/g, '$1,$2');

              // replace coma with dot in negative numbers with double double quotes
              row = row.replace(/""(-\d+),(\d+)""/g, '$1,$2');

              // Replace double quotes with single quotes around numbers
              row = row.replace(/""(\d+,\d+)""/g, '"$1"');

              // Replace commas with space in text in double double quotes
              row = row.replace(/""([^""]+)""/g, function (match, p1) {
                //check if it's a number
                if (p1.match(/^\d{1,9},\d{1,5}$/g)) {
                  return p1.replace(/,/g, ',');
                } else {
                  return p1.replace(/,/g, ' ');
                }
              });

              // Replace commas with space in text in double quotes
              row = row.replace(/"([^""]+)"/g, function (match, p1) {
                //check if it's a number
                if (p1.match(/^\d{1,9},\d{1,5}$/g)) {
                  return p1.replace(/,/g, ',');
                } else {
                  return p1.replace(/,/g, ' ');
                }
              });

              //Remove double quotes inside text in double quotes
              row = row.replace(/"([^"]*(?:""[^"]*)*)"/g, function (match, p1) {
                return p1.replace(/""/g, '');
              });

              // Remove double double quotes around text
              row = row.replace(/""(.*?)""/g, '$1');

              // remove 4 double quotes before the text and 6 doubles quotes behind the text
              row = row.replace(/""""(.*?)""""""/g, '$1');

              // Remove double quotes around text
              row = row.replace(/"(.*?)"/g, '$1');

              return row;
            });
            return modifiedRows.join('\r\n');
          },
          transformHeader: (header) => {
            let ord = 0;
            let result: string = header;
            altFields.forEach((e) => {
              if (e.includes(header)) {
                result = e[0];
                if (this.debugModeActivated) {
                  console.log(header + ' devient ' + result);
                }
              }
            });

            while (this.headers.includes(result)) {
              //If header name is already used
              result = header + '_' + ord++; //Transform into <HEADER>_<N+1>
            }
            //this.headers.push(result); //Stocks the header's name for future duplicate checks
            return result; //Applies new header
          },
          transform: (data: string, headerName: string | number) => {
            if (data == '') return data;

            if (
              headerName.toLocaleString().includes('Charge estimÃ©e') ||
              headerName.toLocaleString().includes('Charge estimée') ||
              headerName.toLocaleString().includes('Montant validÃ©') ||
              headerName.toLocaleString().includes('Montant validé')
            ) {
              return data.replace('.', ',').replace(' ', '');
            }
            return data;
          },
          error: (err, file) => {
            asc.openErrorSnackBar("L'import JIRA a échoué.");
            throwError(err);
          },
          complete: (result) => {
            if (this.debugModeActivated) {
              console.log(result);
            }
            // Check if 'Composants' column exists and 'Componsants / Composant' does not exist after
            const headers = result.meta.fields ?? [];

            const idTicketIndex = headers.indexOf('ID de ticket');
            const resumeIndex = headers.indexOf('Résumé');

            // Count the number of occurrences of 'Composants' between 'ID de ticket' and 'Résumé'
            const composantsCount = headers
              .slice(idTicketIndex + 1, resumeIndex)
              .filter((h) => h === 'Composants').length;

            let newData = result.data;
            const newHeaders = headers;

            if (composantsCount === 1) {
              // Check for similar columns to 'Composants'
              const hasSimilarColumns = headers
                .slice(idTicketIndex + 1, resumeIndex)
                .some((h) => h.includes('Composants') && h !== 'Composants');

              if (!hasSimilarColumns) {
                // Add 'Composant' column in data
                newData = result.data.map((row: object) => {
                  const newRow = { ...(row) };
                  const composantsIndex =
                    Object.keys(newRow).indexOf('Composants') + 1;
                  const before = Object.entries(newRow).slice(0, composantsIndex);
                  const after = Object.entries(newRow).slice(composantsIndex);
                  const newEntries = [...before, ['Composants', ''], ...after];
                  return Object.fromEntries(newEntries);
                });

                // Add 'Composant' in headers
                const newComposantsIndex = headers.indexOf('Composants') + 1;
                newHeaders.splice(newComposantsIndex, 0, 'Composant');
              }
            }

            // CSV file generation with new data and headers
            const csv = this.papa.unparse(
              {
                fields: newHeaders,
                data: newData,
              },
              {
                delimiter: ';',
                newline: '\r\n',
                header: true,
              },
            );
            if (this.debugModeActivated) {
              //download file for debug
              let csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
              let link = document.createElement('a');
              let url = URL.createObjectURL(csvBlob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'import_Jira_DEBUG.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
            // Convert CSV to JSON
            generatedJSON = newData;

            // Filtering lines if necessary
            generatedJSON = generatedJSON.filter((a: any) => this.filterRow(a));

            // Send JSON data to backend
            this.postFileData(generatedJSON, asc, startDate);
          },
        });
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

/*   async postFileData(
    fileData: any,
    asc: ApplicationSettingsComponent,
    startDate: Date,
  ): Promise<any> {
    if (this.debugModeActivated) {
      console.log("Données envoyées au backend :")
      console.log(fileData);
    }
    try {
      const resp = await this.http.post(this.API_URL, fileData).toPromise();
      if (this.debugModeActivated) {
        console.log("Reponse du backend");
        console.log(resp);
      }
      this.jiraErrors = resp;
      const endDate = new Date();
      const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      asc.openSuccessSnackBar(
        "L'import JIRA a été effectué avec succès en " + seconds + ' s.',
      );

      return resp;
    }
    catch (err: any) {
      console.log(err);
      if (err.status == 400) {
        asc.openErrorSnackBar("L'import JIRA a échoué. Le fichier est probablement vide.");
      } else {
        asc.openErrorSnackBar("L'import JIRA a échoué.");
      }
    }
  } */

    // conversion de JSON en Blob et envoi au backend qui attends du multipart/form-data
    async postFileData(
      fileData: any,
      asc: ApplicationSettingsComponent,
      startDate: Date,
    ): Promise<any> {
      if (this.debugModeActivated) {
        console.log("Données envoyées au backend :");
        console.log(fileData);
      }
    
      try {
        // Convert JSON data to Blob
        const jsonBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
    
        // Create FormData object
        const formData = new FormData();
        formData.append('file', jsonBlob, 'data.json');
    
        // Send the FormData to the backend
        const resp = await this.http
          .post(this.API_URL, formData, {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
            }),
          })
          .toPromise();
    
        if (this.debugModeActivated) {
          console.log("Réponse du backend");
          console.log(resp);
        }
    
        this.jiraErrors = resp;
        const endDate = new Date();
        const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        asc.openSuccessSnackBar(
          "L'import JIRA a été effectué avec succès en " + seconds + ' s.',
        );
    
        return resp;
      } catch (err: any) {
        console.log(err);
        if (err.status == 400) {
          asc.openErrorSnackBar("L'import JIRA a échoué. Le fichier est probablement vide.");
        } else {
          asc.openErrorSnackBar("L'import JIRA a échoué.");
        }
      }
    }

  filterRow(row: any): boolean {
    //Rule: Demand state cannot be null nor abandonned
    if (row[this.STATE_HEADER] == null) {
      return false;
    }

    //Rule: Service type must be either "CORRECTION-DEFAUT" OR "DEVIS-ESTIMATIF"
    if (
      row[this.SERVICE_TYPE_HEADER] != null &&
      (row[this.SERVICE_TYPE_HEADER] == this.DEFAULT_CORRECT ||
        row[this.SERVICE_TYPE_HEADER] == this.ESTIMATED_QUOTE)
    ) {
      return false;
    }

    //Rule: Demand type must be of type "Demand"
    if (
      row[this.DEMAND_TYPE_HEADER] == null ||
      row[this.DEMAND_TYPE_HEADER] != this.DEMAND
    ) {
      return false;
    }
    return true;
  }

  postFilePack(data: any, year: string, month: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('year', year);
    formData.append('month', month.toString());
    return this.http
      .post<string>(
        environment.backendApiUrl + '/import/pack',
        formData,
        this.options,
      )
      .pipe(catchError(this.utilsService.handleError));
  }

  options = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' }),
  };

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `Erreur réseau : ${err.error.message}`;
    } else {
      errorMessage = `Code erreur : ${err.status}, erreur : ${err.message}`;
    }
    return throwError(errorMessage);
  }

  getPack(): Observable<any> {
    return this.http
      .get(environment.backendApiUrl + '/export/pack/', {
        responseType: 'blob',
      })
      .pipe(catchError(this.utilsService.handleError));
  }

  getTimedPack(year: string, apps: string[]): Observable<any> {
    return this.http
      .get(
        environment.backendApiUrl + '/download/pack/' + year + '?apps=' + apps,
        { responseType: 'blob' },
      )
      .pipe(catchError(this.utilsService.handleError));
  }

  getCalculDues(): Observable<any> {
    return this.http
      .get(environment.backendApiUrl + '/jobLauncher', {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' }),
        responseType: 'text',
      })
      .pipe(catchError(this.utilsService.handleError));
  }

  /*
   **** ALERT JAVA
   */

  preTreatmentFile(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this._es.getAltFields().subscribe({
        next: (data) => {
          this.papa.parse(file, {
            download: true,
            header: true,
            dynamicTyping: true,
            encoding: 'utf-8',
            delimiter: '', // auto-detect
            delimitersToGuess: [',', ';'],
            newline: '\r\n',
            beforeFirstChunk: function (chunk) {
              // Remove quotes at the beginning and end of the line, and the semicolon at the end of the line if present
              const rows = chunk.split(/\r\n|\r|\n/);
              const modifiedRows = rows.map((row) => {
                // Remove the quote at the beginning of the line if present
                if (row.startsWith('"')) {
                  row = row.substring(1);
                }
                // Remove the semicolon at the end of the line if present
                if (row.endsWith(';')) {
                  row = row.substring(0, row.length - 1);
                }
                // Replace coma with dot in numbers and delete space before number
                row = row.replace(/"\s(-?\d+\,\d+)"/g, '$1');

                // Replace dot by coma in number for number without double quotes
                row = row.replace(/(;-?\d+\.\d+;)/g, function (match) {
                  return match.replace('.', ',');
                });

                // Replace &amp; with &
                row = row.replace(/&amp;/g, '&');

                // remove € symbol
                row = row.replace(/€/g, '');

                // remove space into number
                row = row.replace(/"(\d+)\s(\d+)"/g, '$1$2');
                row = row.replace(/"(\d+)\s(\d+),(\d+)"/g, '$1$2,$3');
                row = row.replace(/"(-\d+)\s(\d+),(\d+)"/g, '$1$2,$3');
                row = row.replace(/"(\d+)\s(\d+),(\d+)\s"/g, '$1$2,$3'); // variant avec un espace apres le nombre
                row = row.replace(/"(-\d+)\s(\d+),(\d+)\s"/g, '$1$2,$3'); // variant avec un espace apres le nombre

                // Remove space after number
                row = row.replace(/"(\d+)\s"/g, '$1');
                row = row.replace(/"(\d+),(\d+)\s"/g, '$1,$2');

                //Remove space before number
                row = row.replace(/\s"(\d+)/g, '$1');
                row = row.replace(/\s"(\d+),(\d+)/g, '$1,$2');

                // remove space into and after number
                row = row.replace(/(\d{1,3})(\s)(\d{3},\d{2})/g, '$1$3');

                // replace coma with dot in negative numbers with double double quotes and remove space
                row = row.replace(/""(-\d+),(\d+)\s""/g, '$1,$2');

                // Replace double quotes with single quotes around numbers and remove space
                row = row.replace(/""(\d+),(\d+)\s""/g, '"$1"');

                // replace coma with dot in negative numbers with double quotes
                row = row.replace(/"(-\d+),(\d+)"/g, '$1,$2');

                // replace coma with dot in negative numbers with double double quotes
                row = row.replace(/""(-\d+),(\d+)""/g, '$1,$2');

                // Replace double quotes with single quotes around numbers
                row = row.replace(/""(\d+,\d+)""/g, '"$1"');

                // Replace commas with space in text in double double quotes
                row = row.replace(/""([^""]+)""/g, function (match, p1) {
                  //check if it's a number
                  if (p1.match(/^\d{1,9},\d{1,5}$/g)) {
                    return p1.replace(/,/g, ',');
                  } else {
                    return p1.replace(/,/g, ' ');
                  }
                });

                // Replace commas with space in text in double quotes
                row = row.replace(/"([^""]+)"/g, function (match, p1) {
                  //check if it's a number
                  if (p1.match(/^\d{1,9},\d{1,5}$/g)) {
                    return p1.replace(/,/g, ',');
                  } else {
                    return p1.replace(/,/g, ' ');
                  }
                });

                //Remove double quotes inside text in double quotes
                row = row.replace(/"([^"]*(?:""[^"]*)*)"/g, function (match, p1) {
                  return p1.replace(/""/g, '');
                });

                // Remove double double quotes around text
                row = row.replace(/""(.*?)""/g, '$1');

                // remove 4 double quotes before the text and 6 doubles quotes behind the text
                row = row.replace(/""""(.*?)""""""/g, '$1');

                // Remove double quotes around text
                row = row.replace(/"(.*?)"/g, '$1');

                return row;
              });
              return modifiedRows.join('\r\n');
            },
            transform: (data: string, headerName: string | number) => {
              if (data == '') return data;

              if (
                headerName.toLocaleString().includes('Charge estimÃ©e') ||
                headerName.toLocaleString().includes('Charge estimée') ||
                headerName.toLocaleString().includes('Montant validÃ©') ||
                headerName.toLocaleString().includes('Montant validé')
              ) {
                return data.replace('.', ',').replace(' ', '');
              }

              return data;
            },
            complete: (result) => {
              // Check if 'Composants' column exists and 'Components / Component' does not exist after
              const headers = result.meta.fields ?? [];

              const idTicketIndex = headers.indexOf('ID de ticket');
              const resumeIndex = headers.indexOf('Résumé');

              // Count the number of occurrences of 'Composants' between 'ID de ticket' and 'Résumé'
              const composantsCount = headers
                .slice(idTicketIndex + 1, resumeIndex)
                .filter((h) => h === 'Composants').length;

              let newData = result.data;
              const newHeaders = headers;

              if (composantsCount === 1) {
                // Check for similar columns to 'Composants'
                const hasSimilarColumns = headers
                  .slice(idTicketIndex + 1, resumeIndex)
                  .some((h) => h.includes('Composants') && h !== 'Composants');

                if (!hasSimilarColumns) {
                  // Add 'Composant' column in data
                  newData = result.data.map((row: object) => {
                    const newRow = { ...(row) };
                    const composantsIndex =
                      Object.keys(newRow).indexOf('Composants') + 1;
                    const before = Object.entries(newRow).slice(
                      0,
                      composantsIndex,
                    );
                    const after = Object.entries(newRow).slice(composantsIndex);
                    const newEntries = [...before, ['Composants', ''], ...after];
                    return Object.fromEntries(newEntries);
                  });

                  // Add 'Composant' in headers
                  const newComposantsIndex = headers.indexOf('Composants') + 1;
                  newHeaders.splice(newComposantsIndex, 0, 'Composant');
                }
              }

              // CSV file generation with new data and headers
              const csv = this.papa.unparse(
                {
                  fields: newHeaders,
                  data: newData,
                },
                {
                  delimiter: ';',
                  newline: '\r\n',
                  header: true,
                },
              );
              // Create a Blob from the CSV string
              const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

              if (this.debugModeActivated) {
                //download file for debug
                let link = document.createElement('a');
                let url = URL.createObjectURL(csvBlob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'alert_Debug.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
              resolve(csvBlob);
            },
            error: (err) => {
              reject(new Error(err));
              console.log(err);
            },
          });
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {

        }
      });
    });
  }

  postFileAlert(data: File): Observable<any> {
    return new Observable((observer) => {
      this.preTreatmentFile(data)
        .then((csvBlob) => {
          const formData = new FormData();
          formData.append('file', csvBlob, data.name);
          this.http
            .post(
              environment.backendApiUrl + '/import/alert?name=' + data.name,
              formData,
              {
                headers: new HttpHeaders({
                  'Access-Control-Allow-Origin': '*',
                }),
                responseType: 'text',
              },
            )
            .subscribe({
              next: (response) => {
                observer.next(response);
                observer.complete();
              },
              error: (err) => {
                observer.error(err);
              },
              complete: () => {
              }
            });
        })
        .catch((error) => {
          observer.error(error);
        });
    }).pipe(catchError(this.utilsService.handleError));
  }

  getAlertCsvByUser(apps: string, role: string): Observable<any> {
    return this.http
      .get(
        environment.backendApiUrl +
        '/download-alerts-csv/user?apps=' +
        apps +
        '&role=' +
        role,
        { responseType: 'blob' },
      )
      .pipe(catchError(this.utilsService.handleError));
  }
}
